import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import lu1 from "../../images/paljeovi2-768x432.jpg"

import col1 from "../../images/PA0410.jpg"
import col2 from "../../images/PA0222.jpg"
import col3 from "../../images/PA0000.jpg"
import col4 from "../../images/PA0010.jpg"
import col5 from "../../images/PA2819.jpg"
import col6 from "../../images/PA2109.jpg"
import col7 from "../../images/PA2010.jpg"
import col8 from "../../images/PA0994.jpg"
import col9 from "../../images/PA0936.jpg"
import col10 from "../../images/PA0887.jpg"
import col11 from "../../images/PA0705.jpg"
import col12 from "../../images/PA0450-1.jpg"
import col13 from "../../images/PA0450.jpg"

import col14 from "../../images/S173.jpg"
import col15 from "../../images/S175.jpg"
import col16 from "../../images/S179.jpg"
import col17 from "../../images/S180.jpg"
import col18 from "../../images/S190.jpg"
import col19 from "../../images/S201.jpg"
import col20 from "../../images/S131.jpg"
import col21 from "../../images/S150.jpg"
import col22 from "../../images/S124.jpg"
import col23 from "../../images/S110.jpg"

import col24 from "../../images/S100.jpg"
import col25 from "../../images/R464.jpg"
import col26 from "../../images/R077.jpg"
import col27 from "../../images/F499.jpg"
import col28 from "../../images/F494.jpg"
import col29 from "../../images/639-SPP.jpg"
import col30 from "../../images/647-SPP.jpg"
import col31 from "../../images/648-SPP.jpg"
import col32 from "../../images/649-SPP.jpg"
import col33 from "../../images/650-SPP.jpg"

import col34 from "../../images/F409.jpg"
import col35 from "../../images/F431.jpg"
import col36 from "../../images/F436.jpg"
import col37 from "../../images/F424.jpg"
import col38 from "../../images/636-SPP.jpg"
import col39 from "../../images/633-SPP.jpg"
import col40 from "../../images/632-SPP.jpg"
import col41 from "../../images/629-SPP.jpg"
import col42 from "../../images/613-SPP.jpg"
import col43 from "../../images/602-SPP.jpg"

import col44 from "../../images/S613.jpg"
import col45 from "../../images/S548.jpg"
import col46 from "../../images/S497.jpg"
import col47 from "../../images/S476.jpg"
import col48 from "../../images/S467.jpg"
import col49 from "../../images/S426.jpg"
import col50 from "../../images/S425.jpg"
import col51 from "../../images/S423.jpg"
import col52 from "../../images/S384.jpg"
import col53 from "../../images/S282.jpg"

import col54 from "../../images/S284.jpg"
import col55 from "../../images/S286.jpg"
import col56 from "../../images/S300.jpg"
import col57 from "../../images/S308.jpg"
import col58 from "../../images/S331.jpg"
import col59 from "../../images/S343.jpg"
import col60 from "../../images/S377.jpg"
import col61 from "../../images/S378.jpg"
import col62 from "../../images/S272.jpg"
import col63 from "../../images/S269.jpg"

import col64 from "../../images/S265.jpg"
import col65 from "../../images/S255.jpg"
import col66 from "../../images/S250.jpg"
import col67 from "../../images/S242.jpg"
import col68 from "../../images/S231.jpg"
import col69 from "../../images/S220.jpg"
import col70 from "../../images/S209.jpg"
import col71 from "../../images/S151.jpg"
import col72 from "../../images/S160.jpg"
import col73 from "../../images/S170.jpg"

const lootsUksed = () => (
  <Layout>
    <SEO title="Lõõtsuksed" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>LÕÕTSUKSED</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link to="/ruumijagajad/voldikuksed/">
          <div className="epo">
            <h2>Voldikuksed</h2>
          </div>
        </Link>
        <Link
          to="/ruumijagajad/lootsuksed/"
          activeStyle={{ borderBottom: "3px solid #ffa609" }}
        >
          <div className="epo">
            <h2>Lõõtsuksed</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/siirdeseinad/">
          <div className="epo">
            <h2>Siirdeseinad</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/voldikseinad/">
          <div className="epo">
            <h2>Voldikseinad</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/referentsid/">
          <div className="epo">
            <h2>Referentsid</h2>
          </div>
        </Link>
      </div>

      <div className="voldikuksed-wrapper">
        <div className="voldikuksed-text">
          <h3>Lõõtsuksed</h3>
          <p>
            Lõõtsuksed valmistatakse tellimusel vastavalt ava suurusele.
            Raamistikku paigaldatud “käär-mehhanism” on terasest.Standardselt
            kasutatakse pinnakattena kunstnahka ja spetsiaalset tugevat riiet.
            Vajaduse korral valmistatakse voldikuksed helikindlusega: – 30 dB/
            2000 Hz – 36 dB/ 2000 Hz Helieristus materjalina kasutatakse
            Melustop-kummivaipa.Üla – kandvusega ( rippuv ) voldikuks liigub
            kuullaager-rullikutega alumiiniumist või terasest juhtlatil.
            Kattematerjalide valik tellija kataloogist.
          </p>
        </div>

        <div className="subpage-img lootsuksed-img">
          <img src={lu1} alt="Lõõtskuks1"></img>
        </div>
      </div>
    </div>

    <h2
      style={{
        textAlign: `center`,
      }}
    >
      Kunstnaha näidised
    </h2>

    <div className="colors-grid">
      <div className="color-inner">
        <img alt="Color" src={col1}></img>
        <p>PA0410</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col2}></img>
        <p>PA0222</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col3}></img>
        <p>PA0000</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col4}></img>
        <p>PA0010</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col5}></img>
        <p>PA2819</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col6}></img>
        <p>PA2109</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col7}></img>
        <p>PA2010</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col8}></img>
        <p>PA0994</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col9}></img>
        <p>PA0936</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col10}></img>
        <p>PA0887</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col11}></img>
        <p>PA0705</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col12}></img>
        <p>PA0450-1</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col13}></img>
        <p>PA0450</p>
      </div>
    </div>

    <h2
      style={{
        textAlign: `center`,
      }}
    >
      Tekstiili näidised
    </h2>

    <div className="colors-grid">
      <div className="color-inner">
        <img alt="Color" src={col14}></img>
        <p>S173</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col15}></img>
        <p>S175</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col16}></img>
        <p>S179</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col17}></img>
        <p>S180</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col18}></img>
        <p>S190</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col19}></img>
        <p>S201</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col20}></img>
        <p>S131</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col21}></img>
        <p>S150</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col22}></img>
        <p>S124</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col23}></img>
        <p>S110</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col24}></img>
        <p>S100</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col25}></img>
        <p>R464</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col26}></img>
        <p>R077</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col27}></img>
        <p>F499</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col28}></img>
        <p>F494</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col29}></img>
        <p>639-SPP</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col30}></img>
        <p>647-SPP</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col31}></img>
        <p>648-SPP</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col32}></img>
        <p>649-SPP</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col33}></img>
        <p>650-SPP</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col34}></img>
        <p>F409</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col35}></img>
        <p>F431</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col36}></img>
        <p>F436</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col37}></img>
        <p>F424</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col38}></img>
        <p>636-SPP</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col39}></img>
        <p>633-SPP</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col40}></img>
        <p>632-SPP</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col41}></img>
        <p>629-SPP</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col42}></img>
        <p>613-SPP</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col43}></img>
        <p>602-SPP</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col44}></img>
        <p>S613</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col45}></img>
        <p>S548</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col46}></img>
        <p>S497</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col47}></img>
        <p>S476</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col48}></img>
        <p>S467</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col49}></img>
        <p>S426</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col50}></img>
        <p>S425</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col51}></img>
        <p>S423</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col52}></img>
        <p>S384</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col53}></img>
        <p>S282</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col54}></img>
        <p>S284</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col55}></img>
        <p>S286</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col56}></img>
        <p>S300</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col57}></img>
        <p>S308</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col58}></img>
        <p>S331</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col59}></img>
        <p>S343</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col60}></img>
        <p>S377</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col61}></img>
        <p>S378</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col62}></img>
        <p>S272</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col63}></img>
        <p>S269</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col64}></img>
        <p>S265</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col65}></img>
        <p>S255</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col66}></img>
        <p>S250</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col67}></img>
        <p>S242</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col68}></img>
        <p>S231</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col69}></img>
        <p>S220</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col70}></img>
        <p>S209</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col71}></img>
        <p>S151</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col72}></img>
        <p>S160</p>
      </div>
      <div className="color-inner">
        <img alt="Color" src={col73}></img>
        <p>S170</p>
      </div>
    </div>
  </Layout>
)

export default lootsUksed
